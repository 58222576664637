import React from "react"
import GridLayout from "../../layout/GridLayout"
import styled, { css } from "styled-components"
import BrandCard from "../BrandCard"
import { navigate } from "gatsby"
import { urlFor } from "../../util/url"
import device from "../../util/devices"

const LayoutContainer = styled(GridLayout)`
  -ms-overflow-x: hidden;
  ${props =>
    Array(props.howMany)
      .fill(0)
      .map(
        (_, i) => css`
          > :nth-child(${i + 1}) {
            grid-row: ${i + 1};
            grid-column: ${i % 2 === 1 ? "1 / 6" : "2 / 7"};
            min-width: auto;
            margin-top: 120px;
          }
        `
      )}
  > :first-child {
    margin-top: 90px;
  }

  @media (${device.tablet}) {
    ${props =>
      Array(props.howMany)
        .fill(0)
        .map(
          (_, i) => css`
            > :nth-child(${i + 1}) {
              grid-column: ${i % 2 === 1 ? "1 / 5" : "3 / 7"};
            }
          `
        )}
  }

  @media (${device.desktop}) {
    > :first-child,
    > :nth-child(3),
    > :nth-child(n + 6),
    > :nth-child(3n + 6) {
      grid-column: 2 / 6 !important;
      margin-top: 160px !important;
    }

    > :nth-child(2),
    > :nth-child(5),
    > :nth-child(2n + 6),
    > :nth-child(5n + 6) {
      grid-column: 7 / 12 !important;
    }

    > :nth-child(4),
    > :nth-child(4n + 6) {
      grid-column: 5 / 10 !important;
      margin-top: 200px !important;
    }
  }
`

const BrandsGrid = ({ brands }) => {
  return (
    <LayoutContainer howMany={brands.length}>
      {brands.map(b => (
        <BrandCard
          height="100%"
          brand={b}
        />
      ))}
    </LayoutContainer>
  )
}

export default BrandsGrid
