import React from "react"
import GridLayout from "../GridLayout"
import styled, { css } from "styled-components"
import device from "../../util/devices"

const Container = styled(GridLayout)`
  ${props => props.extraPadTop && css`margin-top: 230px;`}
  > * {
    grid-column: 1 / 7;
  }
  ${[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
    i =>
      css`
        > :nth-child(${i}) {
          grid-row: ${i};
        }
      `
  )};

  .photo {
    margin-top: 35px;
  }

  .person {
    margin-top: 40px;

    & + .person {
      margin-top: 60px;
    }
    > *,
    p {
      display: inline;
    }
  }

  padding-bottom: 150px;

  @media (${device.desktop}) {
    align-items: start;
    padding-top: ${props => props.extraPadTop ? 30 : 15}%;
    ${props => props.extraPadTop && css`margin-top: 20%;`}
    min-height: 200vh;
    padding-bottom: 0;
    .photo {
      grid-column: 8 / 12;
      grid-row: 1 / 2;
      position: sticky;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100vh;
      top: 0;
      > :first-child {
        width: 100%;
        max-height: 75vh;
      }
    }

    .intro {
      grid-row: 1;
      margin: 0 auto;
      display: block;
      grid-column: 2 / 6;
    }

    .person {
      margin-top: 0 !important;
      padding-bottom: 50%;
      grid-row: 2;
      grid-column: 2 / 5;
      & + .person {
        grid-column: 5 / 8;
      }
    }
  }
`

const AboutUsSection = props => (
  <Container {...props}>{props.children}</Container>
)

export default AboutUsSection
