import React from "react"
import styled, { css } from "styled-components"
import GridLayout from "../GridLayout"
import device from "../../util/devices"

const Container = styled(GridLayout)`
  padding-top: 125px;
  .area-title {
    margin-top: 30px;
  }
  ${[1, 2, 3, 4, 5, 6, 7, 8].map(
    i => css`
      > :nth-child(${i}) {
        grid-row: ${i};
        grid-column: 1 / 7;
      }
    `
  )};

  button:last-child {
    margin-top: 20px;
  }
  @media (${device.desktop}) {
    padding-top: 250px;
    .area-title {
      margin-top: 0;
      margin-left: -20px;
      letter-spacing: -0.03em;
    }
    > :first-child {
      grid-row: 1;
      grid-column: 2/6;

      p {
        margin-top: 0;
      }
    }

    > button.contact-mobile {
      display: none;
    }

    ${[2, 3, 4, 5, 6, 7, 8, 9, 10].map(
      i => css`
        > :nth-child(${i}) {
          grid-row: ${Math.floor(i / 2)};
          grid-column: ${i % 2 === 0 ? "7 / 8" : "8 / 11"};
          ${i === 3 && "margin-top: 6px"};
          ${i > 3 &&
          css`
            margin-top: ${i % 2 === 0 ? 62 : 68}px;
          `}
        }
      `
    )}

    .area-desc p {
      margin-top: 0;
    }
  }
`

const WhatWeDoSection = ({ children, ...props }) => (
  <Container as="section" {...props}>
    {children}
  </Container>
)

export default WhatWeDoSection
