import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as S from "./styled"
import SVG from "react-inlinesvg"

const SectionPager = () => {
  const { icon } = useStaticQuery(graphql`
    {
      icon: datoCmsAsset(filename: { eq: "ic-arrow-up.svg" }) {
        url
      }
    }
  `)

  const [canGoUp, setCanGoUp] = React.useState(false)
  const [canGoDown, setCanGoDown] = React.useState(false)

  const scrollListener = () => {
    setCanGoUp(window.pageYOffset > 10)
    setCanGoDown(
      window.innerHeight + window.pageYOffset + 10 <= document.body.offsetHeight
    )
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollListener, { passive: true })
    return () => window.removeEventListener("scroll", scrollListener)
  })

  const scrollUp = () => {
    window.scrollBy({ top: window.innerHeight * -0.7, behavior: "smooth" })
  }

  const scrollDown = () => {
    window.scrollBy({ top: window.innerHeight * 0.7, behavior: "smooth" })
  }

  setTimeout(() => scrollListener(), 400)
  console.log("pager index called")

  return (
    <S.Container>
      <div
        className={canGoUp ? "available" : null}
        onClick={() => canGoUp && scrollUp()}
      >
        <SVG src={icon.url} />
      </div>
      <div
        className={canGoDown ? "available" : null}
        onClick={() => canGoDown && scrollDown()}
      >
        <SVG src={icon.url} />
      </div>
    </S.Container>
  )
}

export default SectionPager
